import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import QRCode from "react-qr-code";
import { connectSocketIO } from "../utils/socket";
import { generateUniqueId } from "../utils/helper";

const uniqueId = generateUniqueId();
const LandingPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [socketData, setSocketData] = useState(null);
  const roomId = searchParams.get("roomId") ?? "";

  useEffect(() => {
    // Set search parameters if not already set
    if (!roomId) {
      setSearchParams({ roomId: uniqueId });
    }
  }, [roomId, setSearchParams]);

  useEffect(() => {
    // Establish Socket.IO connection
    const ws = connectSocketIO(roomId);
    ws.on("connect", () => {
      console.log("Connected to socket.IO server");
    });

    ws.on("message", (data) => {
      const wsData = JSON?.parse(data);
      if (wsData) {
        setSocketData(wsData);
      }
      if (wsData?.gamesPlayed) {
        localStorage.setItem("totalPlayed", wsData.gamesPlayed);
      }
    });

    ws.on("disconnect", (reason) => {
      console.log("Socket.IO connection closed", reason);
    });

    ws.on("connect_error", (error) => {
      console.error("Socket.IO connection error", error);
    });

    // Cleanup function to close socketIO.IO connection when component unmounts
    return () => {
      ws.disconnect();
    };
  }, [roomId]);

  useEffect(() => {
    if (socketData?.goToHomePage === true) {
      navigate(`/home?roomId=${roomId}`);
    }
  }, [socketData?.goToHomePage, navigate, roomId]);

  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <div className="fixed top-5 left-5">
        <img src="images/logo.png" alt="dove logo" width={132} height={28} />
      </div>
      <div className="flex items-center justify-center flex-col gap-4">
        <div className="p-3 bg-white border-2 border-[#e3bd79]">
          <QRCode
            size={132}
            value={`${window?.location?.origin}/futurehair?roomId=${roomId}`}
          />
        </div>
        <img
          src="images/hairRepairText.png"
          alt="dove text"
          width={306}
          height={56}
        />
        <h5 className="text-[17px] tracking-widest font-bold">
          SCAN ME FOR A GLIMPSE
        </h5>
      </div>
    </div>
  );
};

export default LandingPage;
