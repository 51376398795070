import React from "react";
import PropTypes from "prop-types";

const CtaButton = ({ href, className }) => {
  const handleClick = () => {
    window.gtag("event", "ENGAGE_DEC_TAP_BUY_NOW_FUTUREHAIR");
  };
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={`${className}`}
      onClick={handleClick}
    >
      <img
        src={`${window.location.origin}/images/ctaBg.png`}
        alt="dove shop"
        width={319}
        height={79}
      />
    </a>
  );
};

CtaButton.propTypes = {
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default CtaButton;
