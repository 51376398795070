import { io } from "socket.io-client";

// Function to connect to Socket.IO server
export const connectSocketIO = (roomId) => {
  // Determine the base URL based on the environment
  const baseURL =
    window.location.hostname === "localhost"
      ? "ws://localhost:3001"
      : "wss://doveopenvault.com"; //live socket url

  // Construct the full URL to pass as a query parameter
  const fullURL = `${window.location.origin}${window.location.pathname}`;

  return io(baseURL, {
    query: { url: fullURL, roomId },
  });
};
