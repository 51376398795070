/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import useBlockBackButton from "../../utils/preventBrowserBack";
import CtaButton from "../../components/ctaButton";
// import { connectSocketIO } from "../../utils/socket";
import useResponsiveScale from "../../utils/dynamicScreenScaling";
let triggerOnce = false;
const MobGame = () => {
  const gameRef = useRef(null);
  const [startAnimate, setStartAnimate] = useState(false);
  // const searchParams = new URLSearchParams(window.location.search);
  // const roomId = searchParams.get("roomId") ?? "";
  const { scaleValue, topValue } = useResponsiveScale();
  useBlockBackButton();

  // useEffect(() => {
  //   const ws = connectSocketIO(roomId);
  //   ws.on("connect", () => {
  //     console.log("Connected to socket.IO server");
  //     window.gtag("event", "load", {
  //       event_category: "User Engagement",
  //       event_label: "ENAGAE_SCAN_QR_CODE",
  //     });
  //   });
  //   ws.on("message", (data) => {});
  //   ws.on("disconnect", (reason) => {
  //     console.log("Socket.IO connection closed", reason);
  //   });
  //   ws.on("connect_error", (error) => {
  //     console.error("Socket.IO connection error", error);
  //   });

  //   socket = ws;

  //   return () => {
  //     ws.disconnect();
  //   };
  // }, []);

  useEffect(() => {
    window.gtag("event", "ENAGAE_SCAN_QR_CODE_FUTUREHAIR");
  }, []);

  const handleStartGame = () => {
    console.log("handleStartGame");
    // handleSendMessage("startGame");
    setStartAnimate(true);
    triggerOnce = true;
    if (gameRef?.current) {
      gameRef?.current.play();
    }
  };

  // const handleSendMessage = (message) => {
  //   console.log(socket);
  //   socket.emit("game-message", message);
  //   socket.emit("home-message", message);
  // };

  useEffect(() => {
    setTimeout(() => {
      if (!triggerOnce) {
        console.log("trigger");
        window.gtag("event", "NOTIFY_DEC_END_SLIDE_FUTUREHAIR");
        handleStartGame();
      }
    }, 7000);
  }, [startAnimate]);

  const handleClick = () => {
    window.gtag("event", "ENGAGE_DEC_TAP_BUY_NOW_FUTUREHAIR");

    window.open(
      "https://www.lazada.com.ph/shop/unilever-beauty/custom-1689166807589.htm?wh_weex=true",
      "_blank"
    );
  };

  return (
    <>
      <input
        className={`fixed top-0 left-0 right-0 bottom-0 w-screen h-screen opacity-0 ${
          startAnimate ? "block z-[99999]" : "hidden z-[1]"
        } transition-all`}
        onClick={handleClick}
        type="button"
      />
      <video
        className={`absolute top-0 left-0 right-0 bottom-0 w-screen h-screen object-cover object-center ${
          startAnimate ? "opacity-100 z-[2]" : "opacity-0 z-[1]"
        } transition-all`}
        muted
        playsInline
        preload="auto"
        width={885}
        height={1621}
        ref={gameRef}
        // onEnded={() => handleSendMessage("gameEnded")}
      >
        <source
          src={`${window.location.origin}/videos/mobEnd.mp4`}
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>

      <video
        className={`absolute top-0 left-0 right-0 bottom-0 w-screen h-screen object-cover object-center ${
          startAnimate ? "opacity-0 z-[1]" : "opacity-100 z-[2]"
        } transition-all`}
        autoPlay
        muted
        playsInline
        preload="auto"
        width={885}
        height={1621}
        ref={(video) => {
          if (video) {
            video.playbackRate = 1.25;
          }
        }}
        loop
      >
        <source
          src={`${window.location.origin}/videos/Mobile-intro.mp4`}
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>

      <div
        className="absolute top-0 left-[50%] w-[885px] h-[1621px] z-[3]"
        style={{
          transform: `translate(-50%, -${topValue}%) scale(${
            scaleValue / 100 + 0.007
          }, ${scaleValue / 100})`,
        }}
      >
        <div className="relative w-full h-full">
          <div className="absolute top-[150px] left-[50%] translate-x-[-50%] z-[3]">
            <img
              src={`${window.location.origin}/images/logo.png`}
              alt="dove logo"
              width={270}
              height={57}
            />
          </div>
          <input
            className={`absolute top-[353px] left-[50.2%] w-[528px] h-[535px] translate-x-[-50%] z-[99] opacity-0 ${
              startAnimate ? "hidden" : "block"
            } transition-all cursor-pointer`}
            onClick={() => {
              window.gtag("event", "ENGAGE_DEC_TAP_VAULT_FUTUREHAIR");
              handleStartGame();
            }}
            type="button"
          />
          {/* <img
            className={`hidden absolute top-[353px] left-[50.2%] w-[528px] h-[535px] translate-x-[-50%] z-[3] ${
              startAnimate ? "opacity-0" : "opacity-100"
            } transition-all`}
            src={`${window.location.origin}/images/doveProduct.png`}
            alt="dove logo circle"
            width={563}
            height={577}
            onClick={handleStartGame}
            role="presentation"
          /> */}
          <div
            className={`hidden w-full items-center flex-col gap-12 absolute top-[940px] left-[50%] translate-x-[-50%] z-[3] ${
              startAnimate ? "opacity-0" : "opacity-100"
            }`}
          >
            <img
              className="animate-pulse"
              src={`${window.location.origin}/images/tapOnScreen.png`}
              alt="tap on screen"
              width={361}
              height={147}
            />
            <img
              src={`${window.location.origin}/images/hairRepairText.png`}
              alt="hair repair text"
              className="w-[518px] h-[95px] object-contain"
              width={518}
              height={95}
            />
          </div>

          <CtaButton
            className="absolute bottom-[80px] left-[50%] translate-x-[-50%] z-[3]"
            href="https://www.lazada.com.ph/shop/unilever-beauty/custom-1689166807589.htm?wh_weex=true"
          />
        </div>
      </div>
    </>
  );
};

export default MobGame;
