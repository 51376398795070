/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connectSocketIO } from "../utils/socket";

const DoohHome = () => {
  const doohRef = useRef(null);
  const navigate = useNavigate();
  const [startAnimate, setStartAnimate] = useState(false);
  // const [socketData, setSocketData] = useState(null);
  const searchParams = new URLSearchParams(window.location.search);
  const roomId = searchParams.get("roomId") ?? "";

  useEffect(() => {
    const ws = connectSocketIO(roomId);
    ws.on("connect", () => {
      console.log("Connected to Socket.IO server");
    });
    ws.on("message", (data) => {
      const wsData = JSON?.parse(data);
      if (data) {
        // setSocketData(wsData);
        if (wsData?.event === "startGame") {
          setStartAnimate(true);
          if (doohRef?.current) {
            setTimeout(() => {
              doohRef?.current?.play();
            }, 800);
            // doohRef.current.playbackRate = 1.25;
          }
        }
        if (wsData?.event === "gameEnded") {
          navigate(`/?roomId=${roomId}`);
          setStartAnimate(false);
        }
      }
    });
    ws.on("disconnect", (reason) => {
      console.log("Socket.IO connection closed", reason);
    });
    ws.on("connect_error", (error) => {
      console.error("Socket.IO connection error", error);
    });

    return () => {
      ws.disconnect();
    };
  }, []);

  return (
    <div>
      <video
        src="videos/Dooh-Itro.mp4"
        className={`absolute top-0 left-0 right-0 bottom-0 w-full h-full object-cover ${
          startAnimate ? "opacity-0 z-[1]" : "opacity-100 z-[2]"
        }`}
        preload="auto"
        playsInline
        autoPlay
        controls={false}
        muted
        width={590}
        height={374}
        ref={(video) => {
          if (video) {
            video.playbackRate = 1.25;
          }
        }}
        loop
      />
      <video
        src="videos/Dooh-Banner.mp4"
        className={`absolute top-0 left-0 right-0 bottom-0 w-full h-full object-cover ${
          startAnimate ? "opacity-100 z-[2]" : "opacity-0 z-[1]"
        }`}
        preload="auto"
        playsInline
        controls={false}
        muted
        width={590}
        height={374}
        ref={doohRef}
      />
    </div>
  );
};

export default DoohHome;
